// chat input box style
.chat-message-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-top: 20px;
  margin-top: 20px;

  .chat-input {
    width: 100%;
    background: #343434;
    border-radius: 12px;
    padding: 15px 35px 15px 20px;
    position: relative;

    textarea {
      border: none;
      padding: 0;
      color: var(--ptf-color-4);
      font-size: 16px;
    }
  }

  .chat-input-label {
    position: absolute;
    width: 10px;
    cursor: pointer;
    right: 25px;
    top: 10px;

    .chat-upload {
      color: white;

      &:hover {
        color: #fa4529;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .chat-btn {
    border: none;
    user-select: none;
    border-radius: 50%;
    background: #343434;
    color: white;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #fa4529;
      transition: all 0.2s ease-in-out;
    }

    @media (max-width: 768px) {
      font-size: 18px;
      width: 40px;
      height: 40px;
    }
  }

  .chat-disabled {
    cursor: no-drop !important;
    border: none;
    user-select: none;
    border-radius: 50%;
    background: #343434;
    color: white;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #fa4529;
      transition: all 0.2s ease-in-out;
    }

    @media (max-width: 768px) {
      font-size: 18px;
      width: 40px;
      height: 40px;
    }
  }
}

.chat-card {
  display: flex;
  justify-content: space-between;
  min-height: 200px;
  background: var(--ptf-color-5);
  padding: 29px;
  font-size: 20px;
  .img-box {
    display: flex;
    flex-grow: 1;
    gap: 16px;
    margin-left: 30px;
    img {
      max-width: 300px;
    }
  }
  .button {
    margin-top: 35px;
    background: #fa4529;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
  }

  @media (max-width: 768px) {
    display: block;
    min-height: 200px;
    .button {
      margin-top: 40px;
      font-size: 18px;
    }
    .img-box {
      margin-left: 0;
      margin-top: 30px;
      max-width: 100%;
    }
  }
  @media (max-width: 400px) {
    font-size: 18px;
    height: 220px;
    .button {
      margin-top: 60px;
      font-size: 16px;
    }
  }
}

.chat-box {
  margin-top: 24px;

  &::-webkit-scrollbar {
    display: none;
  }

  .chat-date {
    position: relative;
    width: 100%;
    height: 1px;
    display: block;
    background: var(--text);
    margin: 50px 0;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -60%);
      width: 160px;
      height: 30px;
      background: var(--body);
    }

    &::after {
      content: attr(data-date);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 140px;
      height: 30px;
      background: var(--text);
      border-radius: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.5px;
      color: var(--black-text);
    }
  }

  .sms-box {
    .user-sms-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .delivery-boy-message {
      background: var(--text);
      border-radius: 25px 25px 25px 0;

      .time {
        width: max-content;
        max-width: 300px;
        left: 0;
      }
    }

    .user-message {
      background: var(--text);
      border-radius: 25px 25px 0 25px;

      .time {
        width: max-content;
        max-width: 300px;
        right: 0;
      }
    }

    .delivery-boy-message,
    .user-message {
      position: relative;
      width: max-content;
      max-width: 45%;
      padding: 15px 20px;
      margin-top: 35px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: var(--black-text);
      @media (max-width: 900px) {
        max-width: 100%;
      }
    }

    .time {
      margin-top: 6px;
      position: absolute;
      top: 100%;
      font-weight: 500;
      line-height: 12px;
      color: white;
      font-size: 0.8rem;
      letter-spacing: 2px;
    }

    @media (max-width: 768px) {
      margin-bottom: 100px;
    }
  }
}
