.ticket-container {
  background: var(--ptf-color-16) !important;
  padding: 22px 29px;
  margin-bottom: 20px;

  p {
    font-size: 22px;
    font-weight: 600;
    color: black;
    cursor: context-menu;
  }

  hr {
    margin: 20px 0;
    opacity: 0.1;
  }

  .card {
    background: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 0;

    .search {
      border: none;
      padding: 8px 12px 8px 12px;
    }
  }
}

.table {
  background: var(--ptf-color-16);
  thead {
    td {
      color: #ffffff;
    }
  }
  .hover-table {
    background: var(--ptf-color-16) !important;
    cursor: pointer;

    .star-table {
      margin-top: -8px;

      span {
        height: 33px !important;
      }
    }

    th,
    td {
      color: #ffffff;
      padding: 10px 16px;
    }
  }
  tbody > tr:hover > * {
    color: #ffffff !important;
  }
}

.loadMoreBtn {
  width: 100%;
  border-radius: 12px;
  height: 50px;
}

.ptf-form-group {
  input.error,
  textarea.error {
    border-bottom: 1px solid var(--ptf-accent-1) !important;
  }
  span.error {
    box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  .rc-pagination-item,
  .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-next .rc-pagination-item-link {
    background-color: transparent;
    color: var(--text);
    border-radius: 8px;
  }
  .rc-pagination-item {
    width: 32px;
    height: 32px;
    line-height: 30px;
    &:focus,
    &:hover {
      border-color: var(--ptf-accent-1);
      a {
        color: var(--text);
      }
    }
  }
  .rc-pagination-item-active {
    border-color: var(--ptf-accent-1);
  }
  .rc-pagination-prev,
  .rc-pagination-next {
    width: 32px;
    height: 32px;
    &:focus,
    &:hover {
      .rc-pagination-item-link {
        border-color: var(--ptf-accent-1);
        color: var(--text);
      }
    }
  }
}
